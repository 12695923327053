<template>
    <div id="repair" class="pa-5">
        <v-container>
            <v-row>
                <v-col>
                    <v-card elevation="1">
                        <v-card-text>
                            <v-alert v-if="formSearch.messageAlert" border="left" type="error" color="red lighten-1" dark dense>{{ formSearch.messageAlert }}</v-alert>
                            <form>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete v-model="formSearch.company" slot="selection" :rules="[() => !!formSearch.company || 'This field is required']" :items="listAllCompany" item-value="companyId" :item-text="(item) => item.companyId + ' - ' + item.companyName" :label="this.$t('company')" :placeholder="this.$t('searchCompany')" required outlined dense hide-details clearable></v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete v-model="formSearch.department" slot="selection" :items="listAllDepartment" item-value="departmentId" :item-text="(item) => item.departmentId + ' - ' + item.departmentName" :label="this.$t('department')" :placeholder="this.$t('searchDepartment')" outlined dense hide-details clearable :disabled="!formSearch.canSelectDepartment"></v-autocomplete>
                                    </v-col>

                                    <v-col>
                                        <v-autocomplete v-model="formSearch.location" slot="selection" :items="listAllLocation" item-value="locationId" :item-text="(item) => item.locationId + ' - ' + item.locationName" :label="this.$t('location')" :placeholder="this.$t('searchLocation')" outlined dense hide-details clearable :disabled="!formSearch.canSelectLocation"></v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete v-model="formSearch.repairType" slot="selection" :items="listAllRepairType" item-value="repairTypeId" :item-text="(item) => item.repairTypeId + ' - ' + item.repairTypeName" :label="this.$t('repairType')" :placeholder="this.$t('searchRepairType')" outlined dense hide-details clearable :disabled="!formSearch.canSelectRepairType"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-menu ref="menuStartDate" v-model="formSearch.menuStartDate" :close-on-content-click="false" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="startDateFormatted" :label="$t('startDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-on:click:append="formSearch.menuStartDate=true" outlined dense hide-details required></v-text-field>
                                            </template>
                                            <v-date-picker v-model="formSearch.startDate" @change="formSearch.menuStartDate = false" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="formSearch.startDate = getTodayDate($t('startDate'))">Today</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="formSearch.menuEndDate" :close-on-content-click="false" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="endDateFormatted" :label="$t('endDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-on:click:append="formSearch.menuEndDate=true" outlined dense hide-details required></v-text-field>
                                            </template>
                                            <v-date-picker v-model="formSearch.endDate" @change="formSearch.menuEndDate = false" no-title scrollable></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn color="primary" type="submit" class="col-sm-12 col-lg-2" :loading="loading" @click.prevent="onDisplayInfoClick" depressed>{{ $t("showInfo") }}</v-btn>
                                    </v-col>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="6" md="12">
                                    <v-row>
                                        <v-col>
                                            <v-btn color="red lighten-4" class="col-12" :disabled="repairHistorys.length === 0 || tableRepair.selected.length === 0" @click.prevent="onPrepareRemoveRepairHistoryTaskBySelected()" depressed>
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t("deletedRepairHistoryTaskByRepairId") }} ({{ tableRepair.selected.length }})
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn color="error" class="col-12" :disabled="repairHistorys.length === 0" @click.prevent="onPrepareRemoveAllRepairHistoryTask()" depressed>
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t("deletedRepairHistoryTask") }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" lg="6" md="12">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :disabled="repairHistorys.length === 0" single-line hide-details outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table v-model="tableRepair.selected" :search="search" show-select :page="page" :headers="headers" :items="repairHistorys" item-key="repairId" :options.sync="options" :loading="loading"></v-data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <ConfirmDialog v-model="showConfirmDialog.val" :msg="this.$t('confirmDialog.msgDefault')" :onConfirm="onRemoveAllRepairHistoryTask" />
        <ConfirmDialog v-model="showConfirmDialog.deleteBySelectedVal" :msg="this.$t('confirmDialog.msgDefault')" :onConfirm="onRemoveRepairHistoryTaskBySelected" />

        <v-dialog v-model="dialogGetOtp" max-width="400" persistent>
            <template v-slot:activator="{}"></template>
            <v-card>
                <v-card-title>{{ this.$t('otpDialog.title')}}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-otp-input v-model="userOtp"></v-otp-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">{{ this.$t('otpDialog.ref')}} : {{ userRef }}</v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">{{this.$t('confirmDialog.no')}}</v-btn>
                    <v-btn color="primary" text @click="confirmDelete">
                        <b>{{this.$t('confirmDialog.yes')}}</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { isEmptyOrNull } from "@/util/StringUtil"
import { handleException } from "@/util/ExceptionUtil"
import { mapActions, mapGetters } from "vuex"
import { APIException } from "@/exception/APIException"
import { CustomException } from "@/exception/CustomException"
import { NotificationModel } from "@/models/NotificationModel"
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import APIRepairService from "@/services/APIRepairService"
import APIMasterService from "@/services/APIMasterService"
import moment from "moment"

export default {
    name: "RepairTaskView",
    components: {
        ConfirmDialog
    },
    inject: ["loadingDialog"],
    data() {
        return {
            search: null,
            page: 1,
            repairHistorys: [],
            loading: false,
            options: {
                itemsPerPage: 10 // Default
            },
            headers: [
                { text: "Repair Id", value: "repairId", sortable: false },
                { text: "Tool Id", value: "toolId", sortable: false },
                { text: "Repair Job Id", value: "repairJobId", sortable: false },
                { text: "Create By User", value: "createByUser", sortable: false },
                { text: "Create Date", value: "createDate", sortable: false },
                { text: "Company Id", value: "companyId", sortable: false },
                { text: "Department Name", value: "departmentName", sortable: false },
                { text: "Location Name", value: "locationName", sortable: false },
                { text: "RepairType Name", value: "repairTypeName", sortable: false }
            ],
            formSearch: {
                company: "",
                startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
                endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
                messageAlert: "",
                menuStartDate: false,
                menuEndDate: false,
                department: "",
                canSelectDepartment: false,
                location: "",
                canSelectLocation: false,
                repairType: "",
                canSelectRepairType: false
            },
            showConfirmDialog: {
                val: false,
                deleteBySelectedVal: false
            },
            dialogGetOtp: false,
            userOtp: "",
            userRef: "",
            tableRepair: {
                selected: []
            },
            deleteBySelected: false
        }
    },
    computed: {
        ...mapGetters({
            listAllCompany: "company/getAllCompany",
            listAllDepartment: "company/getAllDepartment",
            listAllLocation: "company/getAllLocation",
            listAllRepairType: "company/getAllRepairType"
        }),
        startDateFormatted() {
            return this.formSearch.startDate ? moment(this.formSearch.startDate).format("DD/MM/YYYY") : ""
        },
        endDateFormatted() {
            return this.formSearch.endDate ? moment(this.formSearch.endDate).format("DD/MM/YYYY") : ""
        }
    },
    watch: {
        options: {
            handler() {
                if (!isEmptyOrNull(this.formSearch.company) && !isEmptyOrNull(this.formSearch.startDate) && !isEmptyOrNull(this.formSearch.endDate)) {
                    this.onGetRepairTaskByComId(this.formSearch.company, this.formSearch.department, this.formSearch.location, this.formSearch.repairType, this.formSearch.startDate, this.formSearch.endDate)
                }
            },
            deep: true
        },
        "formSearch.company"() {
            this.handleCompanyChange()
        }
    },
    mounted() {
        this.getListCompany()
    },
    methods: {
        ...mapActions({
            getListCompany: "company/getListCompany",
            getListDepartment: "company/getListDepartment",
            setNotification: "notification/setSnackbar",
            getListLocation: "company/getListLocation",
            getListRepairType: "company/getListRepairType"
        }),
        handleCompanyChange() {
            if (!isEmptyOrNull(this.formSearch.company)) {
                this.formSearch.canSelectDepartment = true
                this.getListDepartment(this.formSearch.company)
                this.formSearch.canSelectLocation = true
                this.getListLocation(this.formSearch.company)
                this.formSearch.canSelectRepairType = true
                this.getListRepairType(this.formSearch.company)
            } else {
                this.formSearch.canSelectDepartment = false
                this.formSearch.canSelectLocation = false
                this.formSearch.canSelectRepairType = false
                this.formSearch.department = ""
                this.formSearch.location = ""
                this.formSearch.repairType = ""
            }
        },
        async onGetRepairTaskByComId(companyId, departmentId, locationId, repairTypeId, startDate, endDate) {
            try {
                this.loading = true
                this.tableRepair.selected = []

                await APIRepairService.getRepairTaskByComId(companyId, departmentId, locationId, repairTypeId, startDate, endDate)
                    .then(res => {
                        this.loading = false
                        this.repairHistorys = res.data
                    })
                    .catch(err => {
                        this.loading = false
                        if (err.response.status === 404) {
                            this.repairHistorys = []
                            this.formSearch.messageAlert = ""
                        }

                        throw new APIException(err.response.status, err.response.data)
                    })
            } catch (err) {
                this.loading = false
                handleException(err)
            }
        },
        onDisplayInfoClick() {
            this.search = null
            this.onPrepareGetRepairTaskByComId()
        },
        onPrepareGetRepairTaskByComId() {
            try {
                if (isEmptyOrNull(this.formSearch.company)) {
                    throw new CustomException(this.$t("msgError.plsSelectCompany"))
                }

                if (isEmptyOrNull(this.formSearch.startDate)) {
                    throw new CustomException(this.$t("msgError.plsSelectStartDate"))
                }

                if (isEmptyOrNull(this.formSearch.endDate)) {
                    throw new CustomException(this.$t("msgError.plsSelectEndDate"))
                }

                if (moment(this.formSearch.startDate).isAfter(this.formSearch.endDate) || moment(this.formSearch.endDate).isBefore(this.formSearch.startDate)) {
                    throw new CustomException(this.$t("msgError.plsSelectValidDateRange"))
                }

                this.formSearch.messageAlert = ""
                this.onGetRepairTaskByComId(this.formSearch.company, this.formSearch.department, this.formSearch.location, this.formSearch.repairType, this.formSearch.startDate, this.formSearch.endDate)
            } catch (err) {
                this.repairHistorys = []
                this.formSearch.messageAlert = handleException(err)
            }
        },
        onPrepareRemoveAllRepairHistoryTask() {
            this.showConfirmDialog.val = true
        },
        async onRemoveAllRepairHistoryTask() {
            try {
                this.showConfirmDialog.val = false
                this.loadingDialog.val = true

                await APIMasterService.getOtp()
                    .then(res => {
                        // console.log(res)
                        this.dialogGetOtp = true
                        this.loadingDialog.val = false
                        this.userRef = res.data[0].REF_ID
                        // this.setNotification(new NotificationModel(res.data))
                        // this.onPrepareGetRepairTaskByComId()
                    })
                    .catch(err => {
                        throw new APIException(err.response.status, err.response.data)
                    })
            } catch (err) {
                this.loadingDialog.val = false
                this.setNotification(new NotificationModel(handleException(err), "red"))
            }
        },
        getTodayDate(menuDate) {
            if (menuDate === this.$t("startDate")) {
                this.formSearch.menuStartDate = false
            } else if (menuDate === this.$t("endDate")) {
                this.formSearch.menuEndDate = false
            }
            return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        },
        async confirmDelete() {
            let otp = this.userOtp
            let ref = this.userRef

            try {
                this.loadingDialog.val = true
                let payloads = {}

                if (this.deleteBySelected) {
                    let lstRepairId = this.tableRepair.selected.map(repair => repair.repairId)
                    payloads = {
                        userOtp: otp,
                        userRef: ref,
                        LIST_REPAIR_ID: lstRepairId
                    }

                    await APIRepairService.removeRepairHistoryTaskBySelected(this.formSearch.company, payloads)
                        .then(res => {
                            this.dialogGetOtp = false
                            this.setNotification(new NotificationModel(res.data.message))
                            this.onPrepareGetRepairTaskByComId()
                            this.loadingDialog.val = false
                            this.userOtp = ""
                            this.deleteBySelected = false
                            this.tableRepair.selected = []
                        })
                        .catch(error => {
                            this.loadingDialog.val = false
                            throw new CustomException(error.response.data.message)
                        })
                } else {
                    payloads = {
                        userOtp: otp,
                        userRef: ref,
                        departmentId: this.formSearch.department,
                        locationId: this.formSearch.location,
                        repairTypeId: this.formSearch.repairType
                    }

                    await APIRepairService.removeAllRepairHistoryTask(this.formSearch.company, this.formSearch.startDate, this.formSearch.endDate, payloads)
                        .then(res => {
                            console.log("ยิงเส้นลบสำเร็จ")
                            this.dialogGetOtp = false
                            this.setNotification(new NotificationModel(res.data.message))
                            this.onPrepareGetRepairTaskByComId()
                            this.loadingDialog.val = false
                            this.userOtp = ""
                        })
                        .catch(error => {
                            this.loadingDialog.val = false
                            throw new CustomException(error.response.data.message)
                        })
                }
            } catch (err) {
                this.setNotification(new NotificationModel(handleException(err), "red"))
            }
        },
        close() {
            this.dialogGetOtp = false
            this.loadingDialog.val = false
        },
        onPrepareRemoveRepairHistoryTaskBySelected() {
            this.showConfirmDialog.deleteBySelectedVal = true
            this.deleteBySelected = true
        },
        async onRemoveRepairHistoryTaskBySelected() {
            try {
                this.showConfirmDialog.deleteBySelectedVal = false
                this.loadingDialog.val = true

                await APIMasterService.getOtp()
                    .then(res => {
                        this.dialogGetOtp = true
                        this.loadingDialog.val = false
                        this.userRef = res.data[0].REF_ID
                    })
                    .catch(err => {
                        throw new APIException(err.response.status, err.response.data)
                    })
            } catch (err) {
                this.loadingDialog.val = false
                this.setNotification(new NotificationModel(handleException(err), "red"))
            }
        }
    }
}
</script>
<style scoped>
#repair {
    height: 100%;
}
</style>
