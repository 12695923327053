<template>
    <v-main>
        <v-container fill-height>
            <v-row>
                <v-col class="col-sm-6 mx-auto">
                    <v-card class="col-sm-12 mx-auto rounded-xl" outlined elevation="2">
                        <v-card-title>{{ $t("appName") }}</v-card-title>
                        <v-divider></v-divider>
                        <form @submit.prevent="onLogin">
                            <v-card-text>
                                <v-alert v-if="formLogin.messageAlert" border="left" color="red lighten-1" dark>{{ formLogin.messageAlert }}</v-alert>
                                <v-text-field outlined dense :label="$t('username')" prepend-inner-icon="mdi-account-circle" v-model="formLogin.inputUsername" />
                                <v-text-field outlined dense :label="$t('password')" :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" v-model="formLogin.inputPassword" />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="info" type="submit" :loading="formLogin.loading" class="col-sm-12">{{ $t("login") }}</v-btn>
                            </v-card-actions>
                        </form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import { isEmptyOrNull } from "@/util/StringUtil"
import { handleException } from "@/util/ExceptionUtil"
import { CustomException } from "@/exception/CustomException"
import APIAuthService from "@/services/APIAuthService"
import { mapActions } from "vuex"
import router from "../router"

export default {
    name: "LoginView",
    inject: ["loadingDialog"],
    data() {
        return {
            showPassword: false,
            formLogin: {
                inputUsername: "",
                inputPassword: "",
                messageAlert: "",
                loading: false
            }
        }
    },
    created() {
        localStorage.removeItem("Access-Token")
    },
    methods: {
        ...mapActions({
            setUserProfile: "user/setUserProfile",
            getListCompany: "company/getListCompany",
            setNotification: "notification/setSnackbar"
        }),
        async onLogin() {
            let username = this.formLogin.inputUsername
            let password = this.formLogin.inputPassword

            try {
                if (isEmptyOrNull(username) || isEmptyOrNull(password)) {
                    throw new CustomException(this.$t("msgError.plsInputUsernamePassword"))
                }

                this.formLogin.messageAlert = ""

                const payloads = {
                    username: username,
                    password: password
                }

                this.formLogin.loading = true
                await APIAuthService.login(payloads)
                    .then(res => {
                        this.formLogin.loading = false
                        this.setUserProfile(res.data.userProfile)
                        localStorage.setItem("Access-Token", res.data.userProfile.token)
                        this.getListCompany()
                        router.push({ name: "Home" })
                    })
                    .catch(error => {
                        throw new CustomException(error.response.data.message)
                    })
            } catch (err) {
                this.formLogin.loading = false
                this.formLogin.messageAlert = handleException(err)
            }
        }
    }
}
</script>
<style scoped>
.v-main {
    height: 100%;
    background: radial-gradient(circle, var(--v-bgLoginSecondary-base) 0%, var(--v-bgLoginPrimary-base) 100%) !important;
}
</style>
