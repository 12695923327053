import APIAuthService from "@/services/APIAuthService";
import router from "../../router"

const state = {
  userProfile: {
    id: 0,
    username: "",
    fullname: ""
  }
};

const getters = {
  getUserProfile: (state) => {
    return state.userProfile;
  },
};

const actions = {
  setUserProfile({ commit }, payload) {
    commit("SET_USER_PROFILE", payload);
  },
  async userProfile({ commit }) {
    const response = await APIAuthService.getUserProfile()
      .catch((error) => {
        console.log(error)
      });
    if (response && response.data) {
      commit("SET_USER_PROFILE", response.data.userProfile);
    }
  },
  async logout({ commit }) {
    const response = await APIAuthService.logout()
      .catch((error) => {
        console.log(error)
      });

    if (response.status === 200) {
      localStorage.removeItem("Access-Token")
      commit("SET_USER_PROFILE", { id: 0, username: "", fullname: "" });
    }
  },
};

const mutations = {
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
