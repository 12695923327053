import APIService from "./base/BaseAPIService";

export default {
  login(loginRq) {
    return APIService().post("/auth/login", loginRq);
  },
  logout() {
    return APIService().put("/auth/logout");
  },
  getUserProfile() {
    return APIService().get("/auth/user/profile");
  },
};
